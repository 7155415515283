<template>
    <main class="main-page"  id="">
        <template v-if="showHeader">
            <section class="page-section mb-3" >
                <div class="container-fluid">
                    <div class="grid justify-content-between align-items-center">
                        <div  class="col " >
                            <div class=" text-2xl text-primary font-bold" >
                                Data
                            </div>
                        </div>
                        <div  class="col-12 md:col-3 " >
                            <router-link :to="`/data/add`">
                                <Button label="Agregar nuevo" icon="pi pi-plus" type="button" class="p-button bg-primary "  />
                            </router-link>
                        </div>
                        <div  class="col-12 md:col-5 lg:col-4 " >
                            <span class="p-input-icon-left w-full">
                            <i class="pi pi-search" />
                            <InputText  placeholder="Buscar" class="w-full" :value="searchText" @input="debounce(() => { searchText = $event.target.value })"  />
                            </span>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <section class="page-section " >
            <div class="container-fluid">
                <div class="grid ">
                    <div  class="col comp-grid" >
                        <div class="flex align-items-center justify-content-around">
                            <div v-if="searchText" :class="filterTagClass">
                                Buscar
                                <Chip class="font-medium px-2 py-1" removable @remove="clearSearch()">{{ searchText }}</Chip>
                            </div>
                        </div>
                        <div >
                            <template v-if="showBreadcrumbs && $route.query.tag && !isSubPage">
                                <Breadcrumb :home="{icon: 'pi pi-home', to: '/data'}" :model="pageBreadCrumb" />
                            </template>
                            <!-- page records template -->
                            <div class="page-records"  >
                                <DataTable :lazy="true"   :loading="loading"    v-model:selection="selectedItems"
                                     :value="records" dataKey="id" @sort="onSort($event)" class="" :showGridlines="false" :rowHover="true" responsiveLayout="stack">
                                    <Column selectionMode="multiple" headerStyle="width: 2rem" />
                                        <Column  field="id" header="Id" >
                                            <template #body="{data}">
                                                <router-link :to="`/data/view/${data.id}`">
                                                    {{ data.id }}
                                                </router-link>
                                            </template>
                                        </Column>
                                        <Column  field="created_date" header="Created Date" >
                                            <template #body="{data}">
                                                {{ data.created_date }}
                                            </template>
                                        </Column>
                                        <Column  field="filename" header="Filename" >
                                            <template #body="{data}">
                                                {{ data.filename }}
                                            </template>
                                        </Column>
                                        <Column  field="file_size" header="File Size" >
                                            <template #body="{data}">
                                                {{ data.file_size }}
                                            </template>
                                        </Column>
                                        <Column  field="value_01" header="Value 01" >
                                            <template #body="{data}">
                                                {{ data.value_01 }}
                                            </template>
                                        </Column>
                                        <Column  field="value_02" header="Value 02" >
                                            <template #body="{data}">
                                                {{ data.value_02 }}
                                            </template>
                                        </Column>
                                        <Column  field="value_03" header="Value 03" >
                                            <template #body="{data}">
                                                {{ data.value_03 }}
                                            </template>
                                        </Column>
                                        <Column  field="value_04" header="Value 04" >
                                            <template #body="{data}">
                                                {{ data.value_04 }}
                                            </template>
                                        </Column>
                                        <Column  field="value_05" header="Value 05" >
                                            <template #body="{data}">
                                                {{ data.value_05 }}
                                            </template>
                                        </Column>
                                        <Column  field="value_06" header="Value 06" >
                                            <template #body="{data}">
                                                {{ data.value_06 }}
                                            </template>
                                        </Column>
                                        <Column  field="value_07" header="Value 07" >
                                            <template #body="{data}">
                                                {{ data.value_07 }}
                                            </template>
                                        </Column>
                                        <Column  field="value_08" header="Value 08" >
                                            <template #body="{data}">
                                                {{ data.value_08 }}
                                            </template>
                                        </Column>
                                        <Column  field="value_09" header="Value 09" >
                                            <template #body="{data}">
                                                {{ data.value_09 }}
                                            </template>
                                        </Column>
                                        <Column  field="value_10" header="Value 10" >
                                            <template #body="{data}">
                                                {{ data.value_10 }}
                                            </template>
                                        </Column>
                                        <Column  field="value_11" header="Value 11" >
                                            <template #body="{data}">
                                                {{ data.value_11 }}
                                            </template>
                                        </Column>
                                        <Column  field="value_12" header="Value 12" >
                                            <template #body="{data}">
                                                {{ data.value_12 }}
                                            </template>
                                        </Column>
                                        <Column  headerStyle="width: 2rem" headerClass="text-center">
                                            <template #body="{data}">
                                                <div class="flex justify-content-end">
                                                    <SplitButton dropdownIcon="pi pi-bars" class="p-button dropdown-only p-button-text p-button-plain" :model="getActionMenuModel(data)">
                                                        <i></i>
                                                    </SplitButton>
                                                </div>
                                            </template>
                                        </Column>
                                    </DataTable>
                                </div>
                                <!-- page loading indicator -->
                                <template v-if="loading">
                                </template>
                                <!-- end of page loading indicator-->
                                <!-- Empty record -->
                                <template v-if="pageReady && !records.length">
                                    <div class="p-3 my-3 text-500 text-lg font-medium text-center">
                                        ningún record fue encontrado
                                    </div>
                                </template>
                                <!-- end of empty record-->
                                <!-- pagination component-->
                                <template v-if="showFooter && pageReady">
                                    <div class="flex justify-content-between align-items-center">
                                        <div class="flex justify-content-center flex-grow-0">
                                            <div v-if="selectedItems.length" class="m-2">
                                                <Button @click="deleteItem(selectedItems)" icon="pi pi-trash" class="p-button-danger" title="Eliminar seleccionado" />
                                            </div>
                                        </div>
                                        <div v-if="paginate && totalPages > 1" class="flex-grow-1">
                                            <Paginator class="border-none bg-transparent py-3" :first="recordsPosition - 1" @page="(event)=>{pagination.page = event.page + 1}" :rows="pagination.limit" :totalRecords="totalRecords">
                                                <template #start>
                                                    <span class="text-sm text-gray-500 px-2">
                                                    Archivos <b>{{ recordsPosition }} de {{ totalRecords }}</b>
                                                    </span>
                                                </template>
                                                <template #end>
                                                </template>
                                            </Paginator>
                                        </div>
                                    </div>
                                </template>
                                <!-- end of pagination component-->
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
</template>
<script setup>
	import {   toRefs, onMounted } from 'vue';
	import { usePageStore } from 'src/store/page';
	import { useApp } from 'src/composables/app.js';
	import { useListPage } from 'src/composables/listpage.js';
	
	const props = defineProps({
		primaryKey : {
			type : String,
			default : 'id',
		},
		pageStoreKey: {
			type: String,
			default: 'DATA',
		},
		pageName: {
			type: String,
			default : 'data',
		},
		routeName: {
			type: String,
			default: 'datalist',
		},
		apiPath: {
			type: String,
			default: 'data/index',
		},
		paginate: {
			type: Boolean,
			default: true,
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showFooter: {
			type: Boolean,
			default: true,
		},
		showBreadcrumbs: {
			type: Boolean,
			default: true,
		},
		exportButton: {
			type: Boolean,
			default: true,
		},
		importButton: {
			type: Boolean,
			default: false,
		},
		multiCheckbox: {
			type: Boolean,
			default: true,
		},
		emptyRecordMsg: {
			type: String,
			default: "ningún record fue encontrado",
		},
		titleBeforeDelete: {
			type: String,
			default: "Eliminar el registro",
		},
		msgBeforeDelete: {
			type: String,
			default: "¿Seguro que quieres borrar este registro?",
		},
		msgAfterDelete: {
			type: String,
			default: "Grabar eliminado con éxito",
		},
		page: {
			type: Number,
			default: 1,
		},
		limit: {
			type: Number,
			default: 10,
		},
		mergeRecords: { // for infinite loading
			type: Boolean,
			default: false,
		},
		search: {
			type: String,
			default: '',
		},
		fieldName: null,
		fieldValue: null,
		sortBy: {
			type: String,
			default: '',
		},
		sortType: {
			type: String,
			default: 'desc', //desc or asc
		},
		isSubPage: {
			type: Boolean,
			default: false,
		},
		filterTagClass: {
			type: String,
			default: 'surface-card p-2 text-500 flex-grow-1 text-center m-1 mb-3 flex-grow-1 text-center',
		}
	});
	
	const app = useApp();
	
	const defaultStoreState = {
		filters: {
		},
		pagination: {
			page: props.page,
			limit: props.limit,
			sortBy: props.sortBy,
			sortType: props.sortType
		},
		searchText: props.search,
		primaryKey: props.primaryKey
	}
	const store = usePageStore(props.pageStoreKey,  defaultStoreState);
	
	// page hooks where logics resides
	const page = useListPage({ store, props });
	
	const {records, filters,  totalRecords,  selectedItems,  pagination,} = toRefs(store.state);
	const { pageReady, loading, searchText, } = toRefs(page.state);
	
	const {  pageBreadCrumb,   totalPages, recordsPosition, } = page.computedProps;
	
	const { load,    exportPage, debounce, clearSearch, onSort,  deleteItem,       } = page.methods;
	
	function getActionMenuModel(data){
		return [
		{
			label: "View",
			to: `/data/view/${data.id}`,
			icon: "pi pi-eye"
		},
		{
			label: "Edit",
			to: `/data/edit/${data.id}`,
			icon: "pi pi-pencil"
		},
		{
			label: "Delete",
			command: (event) => { deleteItem(data.id) },
			icon: "pi pi-trash"
		}
	]
	}
	
	onMounted(()=>{ 
		const pageTitle = "Data";
		app.setPageTitle(props.routeName, pageTitle);
	});
</script>
<style scoped>
</style>
